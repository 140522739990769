.search__container {
    justify-content: center;
    padding-top: 32px;
}
.search__body{
    width: 100%;
}
.search__body--criteria{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.search__heading {
    color: var(--dsThemeColor);
    font-weight: bold;
    font-weight: 700;
    font-size: 40px;
    margin-block: 0px 14px;
}
.searchForm{
    padding-inline: 5px;
    display: grid;
    grid-template-columns: 170px 300px;
    margin-bottom: 10px;
    align-items: center;
}
.searchForm--Input > label{
    margin-inline-end: 10px;
    width: fit-content;
}
.btn-group {
    display: flex;
    padding-block: 10px;
    padding-left: 5px;
}
.btn-group.column{
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}
.btn-group:not(.column) button + button {
    margin-inline-start: 10px;
}
.btn-group.column button + button {
    margin-block-start: 10px;
}
.btn-icon{
    display: flex;
    gap: 5px;
}
.inputError{
    padding-left: 5px;
    margin-top: 5px;
    font-weight: 700;
    color: crimson;
}
.search__results{
 
}