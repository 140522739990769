.loading__layout{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 20px;
	height: 600px;
	width: 800px;
	box-shadow: rgba(9, 11, 11, 0.2) 0px 4px 16px;
	border-radius: 12px;
	border: 1px solid rgb(202, 208, 216);
	position: relative;
}
.assetDetails {
	background-color: white;
	padding: 20px;
	height: 600px;
	width: 800px;
	box-shadow: rgba(9, 11, 11, 0.2) 0px 4px 16px;
	border-radius: 12px;
	border: 1px solid rgb(202, 208, 216);
	display: grid;
	position: relative;
}
.assetDetails.row{
	grid-template-columns: 100%;
	grid-template-rows: minmax(30%, auto) 1fr;
	grid-template-areas: "content" "preview";
}
.assetDetails.column{
	grid-template-columns: 60% 40%;
	grid-template-rows: 100%;
	grid-template-areas: "content preview";
}
.assetDetails--preview {
	grid-area: preview;
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;
}
.assetDetails--preview > .image-download{
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(90, 91, 91, 0.7);
}
.assetDetails--preview:hover > .image-download{
	visibility: visible;
	cursor: pointer;
}
.video-wrapper{
	/* width: 100%;
	height: 100%; */
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.contained-video{
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
	max-width: 100%;
	max-height: 100%;
}

.assetDetails--content {
	grid-area: content;
	display: flex;
}
.assetDetails--content.row{
flex-direction: row;
justify-content: space-between;
}
.assetDetails--content.column{
flex-direction: column;
}
.assetDetails--content.row >.btns{
	margin-top: 45px;
	width: 160px;
	margin-right: 20px;
}

.assetDetails--heading {
	color: var(--dsThemeColor);
	font-size: 20px;
	font-weight: 700;
}
.assetDetails--body {
	display: grid;
	padding: 10px;
	padding-top: 20px;
	width: 100%;
}
.assetDetails--body--item {
	display: flex;
	align-items: start;
	margin: 5px 0px;
}
.assetDetails--body--item > .title {
	margin-right: 10px;
	color: var(--dsThemeColor);
	font-weight: 700;
	white-space: nowrap; 

}
.assetDetails--body--item > .value {
	color: black;
	font-weight: 300;
	word-break: break-word;
	hyphens: auto;
	white-space: pre-wrap;
}
.btn__hasDropdown{
	position: relative;
}
.btn--dropdown{
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: var(--dsThemeColor);
	padding: 5px;
	border-radius: 5px;
	top:40px;
	left: 0px;
}
.btn--dropdown--links{
	text-decoration: none;
	/* background-color: white;
	border-radius: 5px; */
	color: white;
	padding: 5px;
}
.btn--dropdown--links:hover{
	color: black;
	background-color: color-mix(in srgb, var(--dsThemeColor) 60%, white);
	border-radius: 5px;
}