.marketing {
	background-color: aliceblue;
	flex-direction: column;
	width: 100%;
}
.headline_title {
	margin: 0;
}
.marketing__sub:not(:last-child) {
	margin-bottom: 20px;
}
.marketing__sub {
	width: 100%;
	display: flex;
	align-items: center;
}
.marketing_stub {
	display: flex;
	width: 100%;
}
.marketing_stub:not(:last-child) {
	margin-bottom: 20px;
}

.marketing_stub__title {
	margin-left: 30px;
    min-width: calc(20% - 30px);
}
