.productAssets {
	background-color: #fbfbfb;
	padding: 30px;
	flex-direction: column;
}
.assets__container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.assetsTypeSubSection {
	/* margin-bottom: 5px; */
	padding: 10px 10px;
	margin-left: 5px;
	display: flex;
	flex-direction: column;
}
.subSectionTitle {
	padding-bottom: 5px;
	border-bottom: solid 1px #9b9fb5;
	color: #9b9fb5;
}
.subSectionContent {
	max-height: 200px;
	/* height: 200px; */
	margin-block: 10px;
	display: flex;
	overflow-y: scroll;
	gap: 10px;
}
.subSectionItem {
    position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
}
.subSectionItem > .subSectionItem__child {
	max-height: 200px;
	height: 200px;
	width: auto;
    min-width: 250px;
	max-width: 500px;
	object-fit: contain;
	object-position: center;
}
.unSupportedType {
    max-height: 200px;
	height: 200px;
	width: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.onHoverInfo {
	position: absolute;
	bottom: 0%;
	left: 0%;
	background-color: rgba(0, 0, 0, 0.263);
	height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	visibility: hidden;
}
.subSectionItem:hover .onHoverInfo{
    visibility: visible;
}

.btns{
    width: 150px;
}