.catalog__container {
	flex-direction: column;
	align-items: start;
	padding-top: 32px;
	width: 100%;
}

.catalog__heading {
	color: var(--dsThemeColor);
	font-weight: bold;
	font-weight: 700;
	font-size: 40px;
	margin-block: 0px 14px;
}
.catalog__body {
	display: grid;
	width: 100%;
	grid-template-rows: minmax(calc(var(--minMainHeight) - 100px), auto);
	grid-template-columns: 1fr 3px 3fr;
	grid-template-areas: "selector divider list";
}
.no__catagory__selector__catalog__body{
	margin: auto;
}
.catalog__body--selector {
	grid-area: selector;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 60px;
 
	/* max-width: 300px; */
	/* background-color: aqua; */
}
.category__selector{
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: space-evenly;
}



.verticalDivider{
grid-area: divider;
border-left: 0.5px solid var(--dsThemeColor);
margin-block: 30px;
}

.catalog__body--list {
	height: fit-content;
	grid-area: list;
	/* background-color: burlywood; */
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	padding-block: 20px;
}