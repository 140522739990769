.login {
	display: grid;
	flex-grow: 1;
	grid-template-rows: 600px 1fr;
	margin-bottom: -70px;
	/* max-width: 1200px; */
}

.banner {

	background-image: url("../../assets/landing_banner.png");  
	background-size: cover;
	background-position: center right -200px;
	background-repeat: no-repeat;
	background-color: black;
	min-height: 430px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.banner_content {
	/* margin-left: 10%; */
	text-align: left;
}

.banner_content h1 {
	font-weight: 700;
	margin: 0;
	color: #e0ffff; /* Light Cyan for "Welcome to" */
}

.banner_content--logo {
	height: 150px;
	width: auto;
	/* background-image: url("../../assets/dealer-source-white.png"); */
	/* background-position: left -20px center; */
	/* background-repeat: no-repeat; */
	/* background-size: cover; */
}

.banner_content h2 span.dealer {
	color: #faf9f8; /* Orange for "DEALER" */
}

.banner_content h2 span.source {
	margin-left: 10px;
	color: #06d4f8; /* Light Green for "SOURCE" */
}

.banner_content p {
	font-size: 16px;
	margin-bottom: 10px;
	margin-top: 0px;
	color: #e0ffff; 
}

.buttons {
	display: flex;
	margin-top: 50px;
}
@media screen and (max-width: 760px) {
	.banner {
		justify-content: center;
		background-size: cover;
		background-position: 10px right;
	}
	.banner_content {
		margin: 0;
		text-align: center;
		color: #023a69;
	}
	.banner_content--logo {
		margin: 0 auto;
		background-position: center;
	}
	.buttons {
		justify-content: center;
	}
}
.login-button {
	padding: 4px 15px;
	font-weight: bold;
}

.register-button {
	padding: 11px 60px;
	background: none;
	color: rgb(233, 226, 226);
	border: 1px solid rgb(233, 226, 226);
	border-radius: 1px;
	cursor: pointer;
	font-size: 18px;
}

.register-button:hover {
	background-color: #d2cccc;
	color: black;
}
.callToAction{
	background-color: black;
	color: #fff;
	display: flex;
	justify-content: center;
	align-content: center;
	padding-block: 50px;
	/* padding-bottom: 40px; */
}
.features {
	margin: 0px;
	min-height: 240px;
	background-color: black;
	color: white;
	display: flex;
	justify-content: center;
	padding-inline: 0px;
	padding-block: 30px;
}
.features>ul{
	margin-block: 0px;
	padding: 0px;
}
.feature {
	/* padding-inline: 12px;
	text-align: center; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.feature-icon {
	color: white;
	font-size: 2.5rem !important;
	
}

.feature h2 {
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 18px;
}

.feature p {
	font-size: 16px;
	line-height: 1.1rem;
	margin: 0;
	max-width: 230px;
	font-weight: 400;
	text-align: center;
}
