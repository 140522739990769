.mainLayout {
	position: relative;
	display: grid;
	grid-template-columns: var(--sideBarCollapsedWidth) 1fr;
	grid-template-rows: 100%;
	grid-template-areas: "nav main";
}
.mainLayout.login{
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-template-areas: "main";
}

.mainLayout__NavBar {
	z-index: 2;
	height: 100%;
	width: fit-content;
	grid-area: nav;
}
.mainLayout__NavBar.active{
	z-index: 3;
}
.mainLayout__Outlet {
	/* flex-grow: 1; */
	grid-area: main;
	position: relative;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: flex-start;
	padding-top: 0.1px;
	padding-bottom: 70px;
}
