.page-container {
  padding: 20px;
  width: 1100px;
}

.page-heading {
  color: #333;
  font-size: 24px;
}

.horizontal-ruler {
  border: 1px solid #ccc;
}

.instructions {
  font-size: 14px;
  color: #555;
}

.subheading {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

.action-icons {
  display: flex;
  gap: 10px;
}

.accept-icon:hover {
  cursor: pointer;
  color: #4caf50;
}

.reject-icon:hover {
  cursor: pointer;
  color: #f44336;
}

.snackbar-success {
  background-color: #4caf50;
}

.snackbar-error {
  background-color: #f44336;
}

.modal-disabled {
  pointer-events: none;
  opacity: 0.5;

}