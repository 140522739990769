.section{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    padding: 30px;
    border-radius: 20px;
}
.sub_section{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}
.sub_section:not(:last-child){
    margin-bottom: 20px;
}
.col_sub_section{
    flex-direction: column;
}
.sub_section__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}
.title{
    color: var(--dsThemeColor);
    font-weight: bold;
    font-weight: 700;
    font-size: 30px;
    margin-block: 0px 20px;
    min-width: 20%;
}
.sub_section__title{
    color: var(--dsThemeColor);
    margin-left: 20px;
    font-weight: 700;
    font-size: 20px;
    /* margin-block: 0px 20px; */
    min-width: calc(20% - 20px);
}