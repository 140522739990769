.footnotes {
    width: 100%;
}

.footnotes p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #666;
}