.specifications {
	background-color: #FCF7F8;
	padding: 30px;
}
.specifications__container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.controls {
	display: flex;
	gap: 20px;
	justify-content: end;
	align-items: center;
	padding-right: 20px;
	font-size: large;
}
.controls p{
	margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.4;
    color: #666;
}
.controls button {
	/* background-color: white; */
	/* padding-inline: 10px; */
	/* padding-block: 5px; */
	display: flex;
	align-items: center;
	justify-content: space-around;
	/* cursor: pointer; */
	/* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.controls div span {
	margin-left: 10px;
}

.specifications__list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	height: min-content;
}

.list_item {
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 5px;
	/* border: 1px solid black; */
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	width: 100%; /* Important! */
	
}
.list__title {
	/* padding-inline: 10px; */
	padding: 10px 20px 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: large;
	cursor: pointer;
}
.list__content {
	display: none;
}
.list__content.open {
	display: block;
}
.list__content li{
	margin-bottom: 10px;
}
.spec__value{
	color: #3c3c42;
}