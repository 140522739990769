.heading__Body {
	flex-grow: 1;
	width: 100%;
}
.heading {
	color: var(--dsThemeColor);
	font-weight: bold;
	font-weight: 700;
	font-size: 40px;
	margin-block: 0px 14px;
}

