.notification {
	position: fixed;
	top: 90px;
	right: 30px;
	z-index: 500;
	padding: 10px 15px;
    border-radius: 10px;
}

.notification.success {
	background-color: #edf7ed;
    border: 0.5px solid green;
}
.notification.alert {
	background-color: #e5f6fd;
}
.notification.warning {
	background-color: #fff4e5;
}
.notification.error {
	background-color: #fdeded;
}
.message {
	display: flex;
    align-items: center;
    justify-content: flex-start;
}
.message p{
    padding-left: 5px;
    margin-block: 0px;
}