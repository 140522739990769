.generate-contracts-container{
  /* display: flex;
  flex-direction: column; */
  width: 1000px;
  /* justify-content: center; */
    padding-top: 32px;
}

.generate-contracts-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}
.page-heading {
  color: var(--dsThemeColor);
  font-weight: bold;
  font-weight: 700;
  font-size: 40px;
  margin-block: 0px 14px;
  text-align: left;
}
.horizontal-ruler {
  height: 2px;
  background-color: var(--dsThemeColor);
  border: 0px;
  margin-block: 10px 24px;
  padding: 0.1px;
}
.generate-contracts-page form {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  /* width: 100%; */
  margin-top: 20px;
}

.dropzone-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.dropzone-area {
  width: 45%;
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease;
}

.dropzone-area:hover {
  border-color: #0056b3;
}

.dropzone-area input {
  display: none;
}

.dropzone-area p {
  margin-top: 10px;
}

.dropzone-area .MuiSvgIcon-root {
  font-size: 40px;
  color: #007bff;
}

.dropzone-area .MuiTypography-body2 {
  color: #444;
}

.dropzone-container .dropzone-area {
  flex: 1;
}

.MuiFormControl-root {
  margin-bottom: 20px;
}
