
.embla {
	overflow: hidden;
}
.embla__container {
	display: flex;
    width: 100%;
    cursor: alias;
}
.embla__slide {
	flex: 0 0 100%;
	min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.embla__slide img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-height: 500px;
    max-width: 1200px;
}
.embla__button{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100px;
    padding: 0;
    margin: 0;
    z-index: 100;
    border: none;
    cursor: pointer;
    display: none;
}
.embla:hover .embla__button{
    display: block;
}
.embla__button__prev{
    left: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0.6825323879551821) 0%, rgba(0,0,0,0) 100%)
}
.embla__button__next{
    right: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6825323879551821) 100%)
}
.embla__button__svg {
    width: 35%;
    height: 35%;
  }