.category__card {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
	margin-inline: 1px;
	height: 250px;
	width: 260px;
	max-width: 260px;
	max-height: 250px;
	border: 0.5px solid black;
    border-radius: 10px;
    background-color: white;
	text-decoration: none;
	color: #2d3e50;
}
.category__card:hover{
    border: 0.5px solid var(--dsThemeColor) ;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.category__card--img {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 195px;
	width: 100%;
	/* background-color: white; */
    border-radius: 10px 10px 0 0;
}
.category__card--img img{
	object-fit: scale-down;
	padding: 10px;
    max-width: 260px;
    max-height: 195px;
}
.category__card--title{
    display: flex;
	justify-content: center;
	align-items: center;
    flex-grow: 1;
	width: 100%;
	max-height: 52px;
    padding-inline: 8px;
	background-color: aliceblue;
	border-radius: 0 0 10px 10px;
	
}
.category__card--title > h3{
	text-align: center;
}
.category__card--ruler{
    align-self: stretch;
    margin: 0px;
    min-height: 2px;
}