.compliance__container {
	flex-direction: column;
	align-items: start;
	padding-top: 32px;
	width: 100%;
}

.compliance__body--list {
	height: fit-content;
	grid-area: list;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	width: 75%;
	margin: auto;
}

.assetCard {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 300px 350px 1fr;
	margin-inline: 10px;
	margin-bottom: 10px;
	height: 120px;
	max-height: 120px;
	padding: 10px;
	gap: 30px;
	border: 0.5px solid black;
	border-radius: 10px;
	background-color: white;
	text-decoration: none;
	color: #2d3e50;
}
