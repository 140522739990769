.globalSearch {
	box-sizing: border-box;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: var(--zIndexglobalSearch);
}
.globalSearch__modal {
	z-index: 200;
	background: #fff;
	margin: 13vh auto auto;
	position: relative;
	width: 640px;
	box-shadow: rgba(9, 11, 11, 0.2) 0px 4px 16px;
	border-radius: 12px;
	border: 1px solid rgb(202, 208, 216);
	font-family: "IBM Plex Sans";
}
.globalSearch__modal--searchBar {
	display: flex;
	border-bottom: 1px solid rgb(223, 226, 231);
	border-top-color: rgb(223, 226, 231);
	border-right-color: rgb(223, 226, 231);
	border-left-color: rgb(223, 226, 231);
	padding: 4px 8px;
}
.globalSearch__modal--searchBar form {
	align-items: center;
	border-radius: 4px;
	display: flex;
	height: 56px;
	margin: 0;
	padding: 0 12px;
	position: relative;
	width: 100%;
}
.globalSearch__modal--searchBar form > label {
	width: 18px;
	height: 18px;
	color: #006bd6;
}
.globalSearch__modal--searchBar form > input {
	height: 100%;
	outline: none;
	appearance: none;
	border: 0px;
	color: hsl(215, 15%, 12%);
	padding-left: 16px;
	padding-right: 8px;
	font-size: 1rem;
	font-weight: 500;
	flex: 1 1 0;
}
.globalSearch__modal--searchBar form > .icon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
.globalSearch__modal__main {
	padding: 10px 12px;
	max-height: 78vh;
	overflow-y: auto;
	scrollbar-color: hsl(215, 15%, 50%) #fff;
	scrollbar-width: thin;
	font-family:
		"IBM Plex Sans",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		Arial,
		sans-serif,
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol";
	font-weight: 700;
}

.heading {
	color: rgb(87, 99, 117);
	font-size: 11px;
	line-height: 11px;
	letter-spacing: 1.6px;
	text-transform: uppercase;
	padding: 5px 24px 0px;
	margin: 0px;
}

.model__home {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-auto-flow: row;
}
.model__home section {
	padding: 0px 5px;
}

.searchHit--list,
.searchHit--innerList {
	margin-top: 10px;
	list-style: none;
	padding-left: 10px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 20px;
}
.searchHit--innerList {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	right: -15px;
	top: 40px;
	z-index: 100;
	transition: all 0s linear;
	transition-behavior: allow-discrete;
}
.searchHit--list > li {
	margin: 6px 0px;
	border: 1px #f1f2f4 solid;
	border-radius: 16px;
	background-color: #fcfcfc;
	cursor: pointer;
}
.searchHit--innerList > li {
	margin: 6px 0px;
	border: 1px #f1f2f4 solid;
	border-radius: 16px;
	background-color: #fcfcfc;
	cursor: pointer;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.searchHit--list > li:hover {
	background-color: #eaf4ff;
	border: 1px #65b3ff solid;
	box-shadow:
		rgba(0, 0, 0, 0.07) 0px 1px 2px,
		rgba(0, 0, 0, 0.07) 0px 2px 4px,
		rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px,
		rgba(0, 0, 0, 0.07) 0px 16px 32px,
		rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.searchHit--innerList > li:hover {
	background-color: #eaf4ff;
	border: 1px #65b3ff solid;
}
.searchHit--list > li > div,
.searchHit--innerList > li > div {
	color: #006bd6;
	padding: 2px 0px 2px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 52px;
}
.searchHit--list > li:hover > div,
.searchHit--innerList > li:hover > div {
	/* color: black; */
}

.searchHit--list > li:hover .searchHit--innerList {
	visibility: visible;
	top: 20px;
	opacity: 100;
	transition-behavior: allow-discrete;
	transition: all 0.3s linear;
}

.list--icon {
	font-size: 1.5em;
	color: #97a1b0;
}
.searchHit--list > li:hover .list--icon {
	color: #006bd6;
}
.list--item {
	padding: 0px 16px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
}
.item--title {
	position: relative;
}
.item--title span {
	margin-right: 4px;
	font-size: 0.85em;
}
.item--desc {
	font-size: 0.65em;
	max-height: 20px;
	overflow-y: hidden;
}
.closeButton {
	position: absolute;
	top: 30px;
	right: 40px;
	color: white;
	cursor: pointer;
}

/* Assets Details*/


.close-button {
	position: absolute;
	right: 0px;
	top: 0px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 0px;
	font-size: 0;
	cursor: pointer;
}
.close-button:hover{
	color: red;
}

.searchResult--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    height: 100%;
}

.searchResult--empty--text {
    margin-top: 1rem;
    font-size: 1.125rem;  /* 18px */
    color: #666;
    font-weight: 500;
	font-family: "SSTPro";
}

.anchorForDownload{
	display: none;
}