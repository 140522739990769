.dimensions{
    background-color: aliceblue;
    width: 100%;
    margin-top: 20px;
    padding: 30px;
    border-radius: 20px;
}
.dimensions__body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.solo_attribute{
    display: flex;
    margin: 10px;
    justify-content: end;
    /* padding-inline: 30px; */
}
.attribute__cardSection{
    display: flex;
    padding: 10px 0px;
    justify-content: space-evenly;
    width: 100%;
}
.attribute__card{
    padding: 15px 10px;
    border-radius: 10px;
    background-color: #fbfbfb;
    display: grid;
    grid-template-columns: auto 5px auto;
    grid-auto-rows: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.attribute{
    display: contents;
}
.attribute__card>.attribute:hover > *{
    background-color: rgba(0, 0, 0, 0.05);
    /* background-color: #e0f0f0; */
}
.attribute__label{
    text-align: left;
    padding-right: 7px;
    padding-left: 5px;
    font-weight: bold;
}
.attribute__value{
    text-align: left;
    padding-left: 7px;
    padding-right: 5px;
    color: rgb(61, 61, 61);
    font-style: italic;
}
.section__english{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}