.productGallery {
	background-color: #FCF7F8;
	flex-direction: column;
	padding: 30px;
}
.productGallery__carousel {
	background-color: white;
	position: relative;
	/* height: 300px; */
	/* border: 1px solid black; */
	width: 100%;
	/* padding-inline: 20px; */
}
.galleryTitle {
	/* padding-left: 30px; */
}
