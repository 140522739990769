.bullets{
    background-color: #FCF7F8;
    flex-direction: column;
    width: 100%;
}
.bullets__sub:not(:last-child){
    margin-bottom:20px ;
}
.bullets__sub{
    width: 100%;
    display: flex;  
}
.bullets__sub ul {
    padding: 0;
    margin: 0;
    /* list-style: none; */
}
.bullets .section__footer p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 1.4;
    color: #666;
}