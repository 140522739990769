.hr-separator {
	background-color: #3c3c42;
	height: 0.5px;
	border: none;
	margin: 0px;
}
.footer {
	grid-area: footer;
	background-color: var(--dsThemeColor);
	/* background-color: #0f1214; */
	padding: 20px 50px;
	padding-top: 70px;
	color: black;
	overflow: hidden;
	display: flex;
	justify-content: center;
	min-height: 390px;
}
.footer--container{
flex-direction: column;
}
.footer-main {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 30px;
}
.footer-column {
	/* flex: 1; */
	text-align: left;
	display: flex;
	flex-direction: column;
}

.footer-column h2 {
	color: white;
	margin-bottom: 10px;
	font-weight: 400;
	font-size: 16px;
}

.footer-column ul {
	list-style: none;
	padding: 0;
	font-weight: 400;
}

.footer-column ul li {
	font-size: 14px;
	margin-bottom: 7px;
	max-width: 250px;
	color: white;
}
.footer-column ul li a {
	text-decoration: none;
	color: white;
}
.footer-column ul li a:hover {
	border-bottom: solid 1px;
	padding-bottom: 1px;
}

.footer-middle{
	display: flex;
	justify-content: end;
	align-items: center;
	width: 100%;
	padding-inline: 20px;
	margin-bottom: 30px;
}
.footer-middle p{
	font-size: 14px;
	margin-bottom: 7px;
	max-width: 250px;
	color: white;
	padding-left: 30px;
}
.social-media {
	text-decoration: none;
	padding-left: 30px;
	padding-top: 10px;
}
.social-media > a {
	display: inline-block;
	color: white;
	background-color: #3c3c42;
	padding: 4px 9px;
	margin-right: 2px;
}
.social-media a:focus {
    outline: 2px solid #4A90E2;
    outline-offset: 2px;
    border-radius: 4px;
}
.fb:hover {
	background-color: blue;
}
.twitter:hover {
	background-color: black;
}
.insta:hover {
	background-color: #CF3F6F;
}
.youtube:hover {
	background-color: red;
}

.footer-bottom {
	background-color: inherit;
	color: white;
	display: flex;
	justify-content: start;
	align-items: center;
	width: 100%;
	padding-inline: 20px;
}


.copyright {
	font-size: 14px;
	color: white;
	font-weight: 300;
}

