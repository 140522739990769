.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-overlay {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .modal-content h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .confirm-btn,
  .cancel-btn {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .confirm-btn:hover {
    background-color: #0056b3;
  }
  
  .cancel-btn {
    background-color: #dc3545;
  }
  
  .cancel-btn:hover {
    background-color: #c82333;
  }
  
  .confirm-btn:disabled {
    cursor: not-allowed;
    opacity: 0.6; 
}
.cancel-btn:disabled{
  cursor: not-allowed;
    opacity: 0.6;
}