/* legalCopy.module.css */
.legalCopy {
    width: 100%;
}

.legalCopy p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 1.4;
    color: #666;
}
