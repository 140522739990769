.expiry__container {
	justify-content: center;
	padding-top: 32px;
	flex-direction: column;
}
.dataGrid {
	width: 100%;
}
.subHeading {
	margin-bottom: 20px;
}
.noRowsOverlay {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.error{
    color: orangered;
    font-weight: bold;
}