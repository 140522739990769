.error_container {
	margin: auto;
	text-align: center;
	box-shadow: 0 14px 18px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	border-radius: 25px;
	background-color: #f8f9fa;
}

.text_container {
	padding: 50px;
}

.title_style {
	color: var(--dsThemeColor);
	font-weight: bold;
	font-weight: 1000;
	font-size: 50px;
	margin-block: 0px 14px;
}

.text_style {
	font-size: 20px;
	margin-bottom: 20px;
}

.error_button {
	margin-top: 5px;
}
