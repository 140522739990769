

  
  .accordion-container {
    margin-block: 40px;
    /* width: 95%;  */
    /* margin: 0 auto;  */
  }
  
  
  .company-info-form .MuiTextField-root {
    margin-bottom: 10px;
  }
  
  .company-info-form {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .MuiTextField-root {
    flex: 1 1 45%;
  }
  

  .MuiTableContainer-root {
    margin-top: 16px;
  }
  
  .MuiTableCell-root {
    text-align: center;
  }
  
  .MuiTableHead-root {
    background-color: #f5f5f5;
  }
  
  .MuiTableRow-root:hover {
    background-color: #f0f0f0;
  }
  
  .add-user-button {
    margin-bottom: 20px; 
    float: right; 
  }
  
  
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-box {
    position: relative;
    width: 400px;
    background-color: white;
    padding: 20px;
    box-shadow: 24px;
    border-radius: 8px;
    outline: none;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 16px;
    color: #000;
    float: right;
  }
  

  .role-mapping-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .modal-actions {
    display: flex;
    justify-content: flex-end; 
    margin-top: 16px;
  }
  
  .send-button {
    min-width: 100px;
  }
  