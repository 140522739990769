.product__container {
	flex-direction: column;
	margin-top: 20px;
}
.stickySection{
	position: sticky;
	top: 5px;
	z-index: 50;
	width: 100%;
}
.sectionNavBar{
	display: flex;
	/* position: sticky; */
	/* top: 51px; */
	/* z-index: 50; */
	background-color: black;
	width: 100%;
	justify-content: space-evenly;
	padding: 0;
	margin: 0;
	padding-block: 5px;
	/* border-radius: 10px; */
	list-style: none;
	transition: all 0.5s linear;
}
.sectionNavBar li{
	padding: 10px;
}
.sectionNavBar a{
	text-decoration: none;
	padding: 0;
	margin: 0;
	color: white;
}
.heroBanner {
	display: grid;
	/* position: sticky; */
	/* top: 0px; */
	width: 100%;
	height: 336px;
	z-index: 50;
	grid-template-columns: 1fr 558px;
	grid-template-rows: 100%;
	/* column-gap: 30px; */
	background-color: white;
	padding-block-end: 30px;
	transition: all 0.8s linear;
}
.heroBanner.small{
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-block-end: 0px;
	background-color: black;
	color: white;
}
.heroBanner .hero_info {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
}
.hero_info_block{
	width: 100%;
	padding-left: 80px;
	padding-block: 30px;
	background: linear-gradient(90deg, rgba(0,0,0,0.4584427521008403) 0%, rgba(0,0,0,0.2399553571428571) 76%, rgba(0,0,0,0) 100%);
}
.heroBanner.small .hero_info_block{
	padding: 0px;
	background: none
}
.heroBanner h3{
    margin-bottom: 15px;
    font-size: 30px;
}
.heroBanner.small h3{
	margin: 0px;
}
.headline {
	display: flex;
	height: min-content;
	width: 100%;
	padding-block: 20px;
	color: black;
	font-family: "SSTPro";
	font-weight: 700;
	font-size: 30px;
	padding-inline: 60px;
    background-color: ghostwhite;
	justify-content: center;
	text-align: center;
	margin-top: 20px;
}

.product__container p {
	margin: 0px;
}
.product__container p ul{
	margin: 0px;
	padding: 0px;
	
}
.innerLinkTag {
	margin: 0;
	padding: 0;
	text-decoration: none;
	width: 100%;
	scroll-margin-top: 100px;
}

.backToTopButton {
	background-color: white;
	width: 50px;
	height: 50px;
	border-radius: 4px;
	position: fixed;
	bottom: 30px;
	right: 30px;
	transition: background-color .3s, 
	  opacity .5s, visibility .5s;
	opacity: 0;
	visibility: hidden;
	z-index: var(--zIndexBackToTopButton);
	border: 1px solid black;
  }
  .backToTopButton.show {
	opacity: 0.4;
	visibility: visible;
  }
  .backToTopButton.show:hover {
	cursor: pointer;
	opacity: 1;
  }
  .backToTopButton:active {
	opacity: 1;
  }
 
  