.main-header {
	grid-area: header;
	/* padding: 1px 40px; */
	border-style: solid;
	background-color: black;
	border-color: rgba(61, 71, 81, 0.3);
	border-width: 0px 0px thin;
	display: flex;
	justify-content: center;
	align-items: center;
}
/* .main-header--container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
} */
/* #region  Left Side Header*/
.header__left {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	height: 100%;
}
.header__left--menuButton {
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
	/* padding-top: 4px; */
	margin-right: 7px;
	margin-left: -2px;
}
.header__left--menuButton svg {
	vertical-align: middle;
}
.logo {
	max-width: 110px;
	width: 110px;
	height: 25px;
}
.separator {
	color: white;
	margin: 0 10px;
	font-size: 24px;
}

/* .dealer-source {
	font-size: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1rem;
	cursor: pointer;
}

.header-dealer {
	font-size: 28px;
	color: #faf9f8;
}

.header-source {
	margin-left: 10px;
	font-size: 28px;
	color: #06d4f8;
	font-weight: bold;
} */
/* #endregion */

.login-button-header {
	padding: 4px 15px;
	font-weight: bold;
	background-color: black;
	color: var(--dsThemeColor);
}

.login-button-header:hover {
	background-color: var(--dsThemeColor);
	border-color: var(--dsThemeColor);
}
.login-button-header:not(:disabled):not(.disabled):active{
	background-color: var(--dsThemeColor);
	border-color: var(--dsThemeColor);
}
/* RIGHT SIDE HEADER */
.button__shawdow-style {
	box-sizing: border-box;
	min-height: 34px;
	min-width: 34px;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0px;
	cursor: pointer;
	padding-left: 4.8px;
	position: relative;
	color: white;
	border-width: 1px;
	border-style: solid;
	border-image: initial;
	border-radius: 12px;
	border-color: rgba(48, 56, 64, 0.5);
	font-size: 0.875rem;
	transition-property: all;
	transition-duration: 150ms;
	/* background-color: rgba(29, 33, 38, 0.4); */
	background-color: rgba(63, 66, 70, 0.2);
	box-shadow:
		rgba(48, 56, 64, 0.2) 0px 2px 0px inset,
		rgb(9, 11, 11) 0px -2px 0px inset,
		rgb(9, 11, 11) 0px 1px 2px 0px;
}
.button__shawdow-style:hover {
	border-color: rgb(61, 71, 81);
	background-color: rgba(69, 70, 70, 0.589);
}
.header__dropdown {
	list-style: none;
	position: absolute;
	right: 0px;
	top: calc(100% + 30px);
	margin: 0;
	padding: 0px 0;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	background: #3c3c42;
	transition: 0.3s;
	border-radius: 12px;
}
.header__dropdown li {
	display: flex;
	color: white;
	padding: 10px 20px;
	font-size: 16px;
	align-items: center;
	white-space: nowrap;
	justify-items: start;
	cursor: pointer;
}
.header__dropdown li:hover {
	background-color: #ffffff10;
}
.header__dropdown li:focus-visible {
	background-color: #ffffff10;
}
.header__dropdown li:focus {
	background-color: #ffffff10;
}
.header__right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.header__search--icon {
	margin-left:0.5rem ;
}
.header__search span {
	margin-right: 10.5rem;
	margin-left: 0.5rem;
	font-size: medium;
}

.header__profile {
	position: relative;
	padding: 10px 0px;
}
.header__profile--button {
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	margin: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-webkit-text-decoration: none;
	text-decoration: none;
	fill: currentColor;
	-webkit-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
	text-align: center;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	height: 34px;
	width: 34px;
}

.language__selector {
	display: flex;
	margin: 0px 20px;
	position: relative;
	padding: 10px 0px;
}
.language__selector--current {
	padding-right: 6px;
}
.language__selector--flag {
	width: 32px;
	height: auto;
	margin-right: 5px;
	padding: 0px 3px;
}
.language__selector--current span {
	padding: 0px 1px;
}
.language__selector--current .arrow-bottom {
	vertical-align: bottom;
	max-width: 8px;
	height: 16px;
	margin-left: 5px;
}
.language__selector:hover .header__dropdown,
.header__profile:hover  .header__dropdown,
/* .header__profile button:focus-visible ~ .header__dropdown, */
/* .language__selector button:focus-visible .header__dropdown, */
.header__dropdown.show{
	opacity: 1;
	top: calc(100% + 10px);
	visibility: visible;
}