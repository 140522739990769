.schedule-amendments-page {
    padding-top: 32px;
    width: 1200px;
  }
  
  .current-schedules, .choose-schedules {
    margin-bottom: 30px;
  }
  
  h1, h2 {
    margin-bottom: 10px;
  }