.moreAssets__container {
	flex-direction: column;
	align-items: start;
	padding-top: 32px;
	width: 100%;
}

.moreAssets--list {
	height: fit-content;
	grid-area: list;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin: auto;
}
