.keyFeatures {
	background-color: aliceblue;
}
.keyFeatures__content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	width: 100%;
}
.keyFeatures__content_flexcol {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex-wrap: wrap;
	height: fit-content;
	row-gap: 30px;
}
.keyFeatures__feature {
	padding: 20px;
	height: min-content;
	border-radius: 5px;
	/* box-shadow:
		rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
}
.white {
	background-color: white;
}
.keyFeatures__feature__headline {
	margin-bottom: 20px;
	font-weight: 900;
	font-size: large;
}
.keyFeatures__feature__headline * {
	font-weight: bold;
}
.keyFeatures__feature__description {
}

.keyFeatures__feature_image {
	box-shadow:
		rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	display: flex;
}
.keyFeatures__feature_image:hover {
	box-shadow:
		rgba(0, 0, 0, 0.07) 0px 1px 2px,
		rgba(0, 0, 0, 0.07) 0px 2px 4px,
		rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px,
		rgba(0, 0, 0, 0.07) 0px 16px 32px,
		rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.keyFeatures__feature_image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.keyFeatures__overlay {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.9);
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	top: 100%;
	transition: top 550ms ease-in-out;
}
.keyFeatures__feature_image:hover > .keyFeatures__overlay {
	top: 0%;
	/* transition: top 0.4s cubic-bezier(0.165, 0.840, 0.440, 1.000); */
}
.keyFeatures__copyToast{
	position: fixed;
	bottom: 100px;
	left:50%;
	transform: translateX(-50%);
	padding: 10px;
	border-radius: 5px;
	background-color: rgb(255, 255, 255);
	z-index: 50;

}