.relatedProducts{
    background-color: #FCF7F8;
	padding: 30px;
	flex-direction: column;
}
.relatedProducts__container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.relatedTypeSubSection {
	/* margin-bottom: 5px; */
	padding: 10px 10px;
	margin-left: 5px;
	display: flex;
	flex-direction: column;
}
.subSectionTitle {
	padding-bottom: 5px;
	border-bottom: solid 1px #9b9fb5;
	color: #9b9fb5;
}
.subSectionContent {
	max-height: 270px;
	/* height: 200px; */
	margin-block: 10px;
	display: flex;
	overflow-y: scroll;
	gap: 10px;
}
.subSectionItem {
    position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	text-decoration: none;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset; */
}
.subSectionItem > p{
 margin-block: 10px;
 padding-block: 10px;
 height: 40px;
 background-color: black;
 color: white;
 text-align: center;
 vertical-align: middle;
}
.subSectionItem > .subSectionItem__child {
	max-height: 200px;
	height: 200px;
	width: auto;
    min-width: 250px;
	object-fit: contain;
	object-position: center;
}