.home {
	box-sizing: border-box;
	position: relative;
	/* padding-bottom: 70px; */
	/* flex-grow: 1; */
}
.home__heroImage {
	margin: auto;
	margin-top: 40px;
	height: 320px;
	width: 1000px;
	background: url("../../assets/landing_banner.png");
	background-position:  center ;
	background-size: contain;
	background-repeat: no-repeat;
	font-size: larger;
	position: relative;
}
.home__heroImage--logo{
	position: absolute;
	height: 130px;
	top: calc(50% - 65px);
}
.home__heroImage span {
	font-size: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.1rem;
	height: 100%;
	/* font-family: "IBM Plex Sans", sans-serif; */
}

.home__heroImage span .header-dealer {
	/* color: white; */
	font-weight: 500;
}

.home__heroImage span .header-source {
	margin-left: 10px;
	color: var(--dsThemeColor);
	font-weight: bold;
}
.home__information {
	max-width: 600px;
	margin: 40px auto;
	/* color: white; */
	text-align: center;
}
.home__information > h1 {
	margin-bottom: 10px;
	font-size: 28px;
}
.home__information > h1 > span {
	color: var(--dsThemeColor);
}
/* .home__information > h1 {
	margin-top: 10px;
	font-size: 28px;
	margin-bottom: 10px;
} */
.home__followUS {
	position: absolute;
	right: 20px;
	top: 100px;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-content: center;
}
.home__followUS span {
	font-weight: bold;
	height: min-content;
	/* display: block; */
	margin-top: 20px;
	transform-origin: right bottom 0;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	/* text-orientation: upright; */
}
.home__followUS--social-media {
	/* background-color: gray; */
	/* border: black 1px solid; */
	margin: 0 auto;
	padding: 4px;
	display: flex;
	flex-direction: column;
	height: 140px;
	/* width: 180px; */
	justify-content: space-around;
	align-content: center;
}
.home__followUS--social-media a {
	background-color: #3c3c42;
	padding: 4px 9px;
	color: white;
}

.home__information > p {
	font-size: large;
	text-align: center;
}
