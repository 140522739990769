.accountSearch {
	padding: 100px 0px;
	flex-grow: 1;
}
.accountSearch > .card {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	min-width: 250px;
	height: 300px;
}
.card > h1 {
	color: #0f90c0;
}
.impersonate-btn {
	background-color: #0f90c0;
	color: white;
	border: none;
	border-radius: 1px;
	padding: 0px;
	cursor: pointer;
	font-size: 18px;
	font-family: "Exo 2", sans-serif;
}
.impersonate-btn:hover {
	background-color: #2e75c7;
	color: white;
}
.impersonate-btn:disabled {
	background-color: grey;
	color: white;
	cursor: auto;
}
.impersonate-btn > div {
	padding: 11px 60px;
}
