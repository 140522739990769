.logoAssetCard {
	display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 300px 350px 1fr;
	margin-inline: 10px;
	margin-bottom: 10px;
	height: 120px;
	max-height: 120px;
	padding: 10px;
	gap: 30px;
	border: 0.5px solid black;
	border-radius: 10px;
	background-color: white;
	text-decoration: none;
	color: #2d3e50;
}
.logoAssetCard:hover {
	border: 0.5px solid var(--dsThemeColor);
	cursor: pointer;
	box-shadow:
		rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.category__card--img {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 300px;
	width: 300px;
    background-color: #8080801f;
}
.category__card--img img {
	object-fit: scale-down;
	max-width: 300px;
	max-height: 100px;
    /* Generates the Transparent GRID like background */
	background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
	background-size: 20px 20px;
	background-position:
		0 0,
		0 10px,
		10px -10px,
		-10px 0px;
}
.category__card--body{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.category__card--body p{
margin: 0;
}