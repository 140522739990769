.fa-arrow-down {
	display: flex;
	transform: rotate(0deg);
	transition: transform 0.2s ease-in-out;
}

.fa-arrow-down.open {
	transform: rotate(90deg);
	/* transition: transform 0.1s linear; */
}
.sideBar {
	box-sizing: border-box;
	width: var(--sideBarCollapsedWidth);
	height: 100%;
	background-color: black;
	color: #00c6d4;
	z-index: var(--zIndexNavBar);
	transition: left 0.3s ease;
	/* display: flex;
	flex-direction: column; */
	transition: width 0.4s cubic-bezier(0.37, 0, 0.63, 1);
	/* overflow-y: scroll; */

}
.sideBar.active {
	width: var(--sideBarOpenWidth);
	border-right: 1px solid white;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
	position: absolute;
	left: 0px;
	top: 0px;
}
#stickyDiv{
	height: var(--minMainHeight);
	overflow-y: auto;
	overflow-x: hidden;
	position: sticky;
	top: 30px;
	transition: all 0.5s ease;
}
.sideBar.active #stickyDiv{
	top: 50px;
}
#scrollDiv{
	display: flex;
	padding: 20px;
	flex-direction: column;
	
}
.sideBar__button{
	display: flex;
	align-items: center;
	justify-content: end;
	min-width: 30px;
	height: 30px;
	margin-left: -2px;
}
.sideBar__button >button{
	display: flex;
}
.sideBar ul {
	list-style: none;
	padding: 0;
	position: relative;
}

.sideBar ul li {
	display: flex;
	flex-direction: column;
}

/* .sideBar::-webkit-scrollbar {
	display: none;
} */
.sideBar > .sideBar__topMenu {
	margin: 0px;
	padding-block: 20px;
	font-size: large;
	overflow: auto;
}
.sideBar > .sideBar__topMenu > li {
	padding: 0px 2px;
}
.sideBar > .sideBar__topMenu > li:hover {
	color: white;
}
.sideBar__menuItem {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px;
	cursor: pointer;
	margin-bottom: 5px;
}
.sideBar__menuItem--icon {
	color: white;
	line-height: 0;
	transition: padding 0.4s cubic-bezier(0.37, 0, 0.63, 1);
	padding-right: 15px;
	padding-bottom: 5px;
	padding-top: 5px;
}
.sideBar.active .sideBar__menuItem--icon {
	padding-right: 10px;
	padding-bottom: 0px;
	padding-top: 0px;
}
.sideBar__menuItem--title-section {
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	opacity: 0;
	transition-property: visibility, opacity;
	transition-duration: 0.3s ;
	transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
	transition-behavior: allow-discrete;
	visibility: hidden;
	display: none;
}
.sideBar.active .sideBar__menuItem--title-section {
	visibility: visible;
	opacity: 1;
	display: flex;
}
.sideBar__menuItem--title {
	text-wrap: nowrap;
}

.sideBar__menuItem--helperIcon {
	display: none;
}
.sideBar.active .sideBar__menuItem--helperIcon {
	display: flex;
}

.sideBar__menuItem--tooltip {
	position: fixed;
	visibility: hidden;
	text-wrap: nowrap;
	left: 75px;
}
/* .sideBar__menuItem--tooltip::before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
} */
.sideBar .sideBar__topMenu > li >.sideBar__menuItem >.sideBar__menuItem--icon:hover {
	border-right: #00c6d4 2px solid;
}
.sideBar.active  .sideBar__topMenu > li >.sideBar__menuItem >.sideBar__menuItem--icon:hover {
	border-right:0px;
}
.sideBar .sideBar__topMenu > li >.sideBar__menuItem >.sideBar__menuItem--icon:hover ~ .sideBar__menuItem--tooltip {
	visibility: visible;
	z-index: 200;
	/* left: 60px; */
	/* top: 50%; */
	color: black;
	line-height: 0;
}

.sideBar.active .sideBar__topMenu > li >.sideBar__menuItem >.sideBar__menuItem--icon:hover ~ .sideBar__menuItem--tooltip {
	display: none;
}

.sideBar__innerMenu {
	max-height: 0px;
	/* height: 0px; */
	overflow: hidden;
	margin: 0px -22px;
	transition-property: max-height,visibility;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-behavior: allow-discrete;
	color: white;
	visibility: hidden;
	border-bottom: #06d4f8 1px solid;
}
.sideBar__innerMenu.active {
	/* height: fit-content; */
	max-height: 2000px;
	visibility: visible;
	transition-behavior: allow-discrete;
	transition-property: max-height,visibility;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0.550, 0.085, 0.680, 0.530);
}
.sideBar__innerMenu > li {
	background-color: #2d2a2a;
	margin-bottom: 2px;
	padding: 0px 22px;
	padding-left: 55px;
	/* text-wrap: nowrap; */
}
.sideBar__innerMenu > li:hover {
	background-color: #285255;
}
.sideBar__innerMenu > li.selected {
	background-color: #285255;
}
.sideBar__innerMenu > li:last-child {
	margin-bottom: 0px;
}

.sideBar__innerMostMenu {
	margin-right: -22px;
	margin-left: -60px;
}
.sideBar__innerMostMenu li {
	padding: 5px 0px;
	padding-left: 70px;
	margin-bottom: 2px;
	/* background-color: #434141; */
	background-color: #1b292a;
}
.sideBar__innerMostMenu li:hover {
	background-color: #2b797f;
}
.sideBar__innerMostMenu li:last-child {
	margin-bottom: 0px;
}
.logout-button {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: rgba(255, 255, 255, 0.1); /* Lighter background for logout button */
	cursor: pointer;
	border-radius: 4px;
	margin-bottom: 25%;
	position: relative;
}
