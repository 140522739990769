.emailPopUp{
    background-color: white;
	padding: 20px;
	min-height: 300px;
	width: 500px;
	box-shadow: rgba(9, 11, 11, 0.2) 0px 4px 16px;
	border-radius: 12px;
	border: 1px solid rgb(202, 208, 216);
	display: flex;
    flex-direction: column;
	position: relative;
    gap: 20px;
}
.emailPopUp h3{
    color: var(--dsThemeColor);
    font-weight: bold;
}
.emailPopUp .subHeading{
    text-align: justify;
}
.sendButton{
    align-self: flex-end;
    justify-self: end;
}
.error{
    color: red;
    display: flex;
    gap: 5px;
}