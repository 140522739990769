.manage-contract-templates {
  padding-top: 32px;
  width: 1000px;
}

hr {
  margin-bottom: 20px;
}

.add-new-file-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 120px;
  margin-top: 20px;
}

.add-new-file-btn:hover {
  background-color: #0056b3;
}
.data-grid-container{
 height: 360px;
 margin-top: 10px;
 width:650px;
 padding: 5px;
}
.actions button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.edit-btn {
  background-color: #ffc107;
  color: black;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.edit-btn:hover {
  background-color: #e0a800;
}

.delete-btn:hover {
  background-color: #c82333;
}
.error-message {
  color: #ff4d4d;
  background-color: #f8d7da;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
}
