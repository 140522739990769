.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
    max-width: 100%;
    text-align: left;
  }
  
  .modal-content h2 {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
    color: #333;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .input-group input[type="file"] {
    padding: 5px;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: center; /* Align buttons in the center */
    gap: 20px;  /* Space between the buttons */
    width: 100%; /* Make sure the buttons span the full width of their container */
    margin-top: 20px;
  }
  
  .submit-btn,
  .close-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 120px; /* Ensure both buttons have the same width */
    height: 40px; /* Ensure both buttons have the same height */
    display: inline-flex; /* Ensures content is aligned properly */
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* Ensure the padding and borders are included in the button's total size */
  }
  
  .submit-btn:hover,
  .close-btn:hover {
    background-color: #0056b3;
  }
  
  .close-btn {
    background-color: #dc3545;
  }
  
  .close-btn:hover {
    background-color: #c82333;
  }
  
  .submit-btn:disabled,
  .close-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  