.download{
    position: sticky;
    bottom:40px;
    margin-left: calc(100% - 230px);
    margin-top: 30px;
    background-color: white;
    /* border: 0.5px solid black; */
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 230px;
}
.downloadHead{
    height: 50px;
    /* border-bottom: 1px solid grey; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}
.downloadBody{
    max-height:120px;
    overflow-y: scroll;
    transition: height 1s linear;
}
.downloadBody.close{
height: 0px;
overflow: hidden;
}
.downloadItem{
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 20px;
}
.downloadItem:first-of-type{
    border-top: 1px solid grey;
}

.downloadItem:not(:last-of-type){
border-bottom: 1px solid grey;
}