.logoutContainer{
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    border-radius: 12px;
    align-items: center;
}
.progress{
    position: relative;
    line-height: 0px;
}
.progressLabel{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}